import React, { useState, useEffect, Fragment } from 'react'
import './index.scss'
import mainPlanService from '@app/api/mainPlanService'
import { isTemplateExpression } from 'typescript'
import utils from '@app/utils/index'
import { useLocation, useHistory } from 'react-router-dom'
export interface IAppProps {}

const members = () => {
  // const {  } = props;
  const fontType = utils.GetUrlHostType()
  const clstagType = utils.GetClstagType()
  const [judgeLogin, setJudgeLogin] = useState('')
  const [userInfo, setUserInfo] = useState<any>({}) //个人用户信息
  const [enterpriseUser, setEnterpriseUser] = useState<any>({}) //企业用户信息
  const [medalsType, setMedalsType] = useState<any>({}) //奖牌数据
  const [isNewUser, setIsNewUser] = useState<any>({}) //是否是新人
  const [userHref, setUserHref] = useState<any>([]) //会员面板图标列表
  const [news, seNews] = useState<any>([]) //特讯

  const login = function () {
    return (
      (location.href =
        'https://passport.jd.com/new/login.aspx?ReturnUrl=' + escape(location.href).replace(/\//g, '%2F')),
      !1
    )
  }
  const regist = function () {
    return (location.href = 'https://reg.jd.com/p/regPage?source=IBG&ReturnUrl=' + escape(location.href)), !1
  }
  const logout = function () {
    return (
      (location.href =
        'https://passport.jd.com/uc/login?ltype=logout&ReturnUrl=' + escape(location.href).replace(/\//g, '%2F')),
      !1
    )
  }
  const getUserInfo = async () => {
    //判断用户是否登录
    var isLoginUser: any = await mainPlanService.isLogin()
    setJudgeLogin(isLoginUser.Identity.IsAuthenticated)
    //如果用户已经登录，获取用户信息
    if (isLoginUser.Identity.IsAuthenticated) {
      var userInfoServer: any = await mainPlanService.userInfoData()
      if (userInfoServer.userLevel == 7) {
        console.log('企业用户')
      } else {
        setUserInfo(userInfoServer)
        var medalsServer: any = await mainPlanService.getMedelsData()
        setMedalsType(medalsServer.data)
        mainPlanService.getIsNewUserData().then((res: any) => {
          setIsNewUser(res.isNew)
        })
      }
    }

    mainPlanService.getRequestData(window['serverIdList'].userIcon).then((res) => {
      let list = res.advertInfos.list
      setUserHref(list.length >= 3 ? list.filter((item, index) => index < 3) : list)
    })
    mainPlanService.getRequestData(window['serverIdList'].messageListId).then((res) => {
      let list = res.advertInfos.list
      // seNews(list.length > 3 ? list.filter((item, index) => index < 2) : list)
      seNews(list)
    })
  }

  const medalsFn = () => {
    let medalsType = {
      level: 'Diamonds',
    }
    switch (medalsType?.level) {
      case 'Regist':
        return (
          <a href="//vip.jd.com/" target="_blank" title="普通用户">
            <span className="def_color">普通用户</span>
          </a>
        )
      case 'Copper':
        return (
          <a href="//vip.jd.com/" target="_blank" title="铜牌用户">
            <i className="user_lvico_2"></i>
            <span className="coppery_color">铜牌用户</span>
          </a>
        )
      case 'Silver':
        return (
          <a href="//vip.jd.com/" target="_blank" title="银牌用户">
            <i className="user_lvico_3"></i>
            <span className="def_color">银牌用户</span>
          </a>
        )
      case 'Gold':
        return (
          <a href="//vip.jd.com/" target="_blank" title="金牌用户">
            <i className="user_lvico_4"></i>
            <span className="golden_color">金牌用户</span>
          </a>
        )
      case 'Diamonds':
        return (
          <a href="//vip.jd.com/" target="_blank" title="钻石用户">
            <i className="user_lvico_5"></i>
            <span className="diamond_color">钻石用户</span>
          </a>
        )
    }
  }
  const newUserTypeFn = () => {
    switch (userInfo.plusStatus) {
      case '0':
      case '1':
      case '2':
      case '4':
        return (
          <a className="renewal" href="//plus.jd.com" clstag="pageclick|keycount|taiwan|login_02">
            <span>开通PLUS 平均省1012元/年</span>
            <i></i>
          </a>
        )
      case '3':
        return (
          <a className="renewal" href="//plus.jd.com" clstag="pageclick|keycount|taiwan|login_02">
            <span>现在续费PLUS会员享特惠价</span>
            <i></i>
          </a>
        )
      case '5':
        return (
          <a className="renewal" href="//plus.jd.com" clstag="pageclick|keycount|taiwan|login_02">
            <span>只差一步~激活PLUS享特权</span>
            <i></i>
          </a>
        )
    }
  }
  const messsageBtLink = () => {
    switch (clstagType) {
      case 'hk':
        return '//hk.jd.com/kuaibao.html'
      case 'taiwan':
        return '//phat.jd.com/10-218.html'
      case 'global':
        return '//phat.jd.com/10-198.html'
    }
  }

  useEffect(() => {
    //初始化信息
    getUserInfo()
  }, [])

  // useEffect(() => {
  //   getUserInfo()
  // }, [pathname])

  return (
    <div className="right_members plus_bg">
      <div className="members_cen">
        {judgeLogin ? (
          userInfo ? (
            <>
              <div className="user_cen">
                <div className="user_head">
                  <div className="head">
                    <a href="">
                      <img
                        className={`${userInfo.plusStatus == 3 ? 'img_plus' : 'img_com'}`}
                        src={
                          userInfo?.imgUrl
                            ? userInfo?.imgUrl
                            : 'https://img10.360buyimg.com/img/jfs/t1/111500/11/54566/8451/6735af47Ff4f93557/252cd149e327a8b1.png'
                        }
                      ></img>
                    </a>
                  </div>
                  {userInfo.plusStatus == 3 ? <div className="user_avatar_plus"></div> : ''}
                </div>

                <div className="user_info">
                  <div className="welcome">
                    HI～
                    <a href="//home.jd.com" target="_blank">
                      {userInfo?.nickName}
                    </a>
                  </div>
                  <div className="user_vip">
                    {/* <a href="//vip.jd.com/" target="_blank" title={userInfo?.userScoreVO?.totalScore}>
                    <i className="jxz"></i>
                  </a> */}
                    {medalsFn()}
                    <span className="line"></span>
                    <a className="logout" href="//vip.jd.com/" target="_blank" onClick={logout}>
                      退出
                    </a>
                  </div>
                </div>
              </div>
              {!isNewUser ? <div className="user_btn">{newUserTypeFn()}</div> : ''}
            </>
          ) : null
        ) : (
          <>
            <div className="user_cen">
              <div className="user_head">
                <div className="head">
                  <a href="" target="_blank">
                    <img src="https://img10.360buyimg.com/img/jfs/t1/111500/11/54566/8451/6735af47Ff4f93557/252cd149e327a8b1.png"></img>
                  </a>
                </div>
              </div>
              <div className="user_info">
                <div className="welcome">HI～{fontType == 'complexFont' ? '歡迎來到京東' : '欢迎来到京东'}!</div>
                <span className="logo">
                  <a
                    onClick={() => {
                      login()
                    }}
                  >
                    {fontType == 'complexFont' ? '登錄' : '登录'}
                  </a>
                  <span className="vertical_line">|</span>
                  <a
                    onClick={() => {
                      regist()
                    }}
                  >
                    {fontType == 'complexFont' ? '註冊' : '注册'}
                  </a>
                </span>
              </div>
            </div>
            <div className="user_btn">
              <a
                className="newUser"
                clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|login_02`}
                target="_blank"
                href="//xinren.jd.com/?channel=99"
              >
                新人福利
              </a>
              <a
                className="plus"
                clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|login_03`}
                target="_blank"
                href="//plus.jd.com"
              >
                <i></i>
              </a>
            </div>
          </>
        )}
        <div className="line"></div>
        <div className="information">
          <div className="information_bt clr">
            <span>{clstagType == 'hk' ? '港澳特訊' : clstagType == 'global' ? '全球特讯' : '台灣特訊'}</span>
            <a
              target="_blank"
              href={messsageBtLink()}
              clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|notice_01`}
            >
              更多<i></i>
            </a>
          </div>
          <div className="information_list">
            <ul>
              {news &&
                news.map((item, index) => {
                  return (
                    <li key={item.advertId}>
                      <a
                        href={item.link}
                        target="_blank"
                        clstag={`pageclick|keycount|${fontType == 'complexFont' ? 'taiwan' : 'global'}|news_0${
                          index + 1
                        }`}
                      >
                        {item.name}
                      </a>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
        <div className="line"></div>
        <div className="after_sales">
          <ul className="clr">
            {userHref &&
              userHref.map((item, index) => {
                return (
                  <li key={item.advertId}>
                    <a href={item.link} target="_blank">
                      <img src={item.pictureUrl}></img>
                      <span>{item.name}</span>
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
        <div className="line"></div>
        <div className="pay_list">
          <div className="pay_title">{fontType == 'complexFont' ? '國際支付' : '国际支付'}</div>
          <div className="center">
            <div
              className="left"
              onClick={() => {
                window.open('https://help.jd.com/user/issue/948-3951.html')
              }}
            >
              <i></i>
              <span>支付中心</span>
            </div>
            <div className="right">
              <div className="pay_img_cen">
                <span className="icon-item">
                  <img
                    width="34"
                    height="15"
                    alt="visa"
                    src="//img20.360buyimg.com/img/jfs/t1/240921/40/21483/4677/6728717eFb821ae79/436f20b556885041.png"
                  />
                </span>
                <div className="icon_line"></div>
                <span className="icon-item">
                  <img
                    width="26"
                    height="16"
                    alt="master"
                    src="//img14.360buyimg.com/img/jfs/t1/92971/21/54854/3224/6728732dF33b77d0d/a3f1dd547dc6f35d.png"
                  />
                </span>
                <div className="icon_line"></div>
                <span className="icon-item">
                  <img
                    width="28"
                    height="16"
                    alt="銀聯"
                    src="https://img14.360buyimg.com/img/jfs/t1/173755/4/51399/3057/672876c8Fdb033fc4/f96be720a02fd8fb.png"
                  />
                </span>
              </div>
              <div className="pay_img_cen">
                <span className="icon-item">
                  <img
                    width="23"
                    height="16"
                    alt="微信"
                    src="//img20.360buyimg.com/img/jfs/t1/176962/34/50798/967/67287356F6ab1e86f/28bcc234627500ff.png"
                  />
                </span>
                <div className="icon_line"></div>
                <span className="icon-item">
                  <img
                    width="34"
                    height="15"
                    alt="八達通"
                    src="//img14.360buyimg.com/img/jfs/t1/149682/8/46733/4108/672876e7Fdf3dcb9b/c4af10395774ba4a.png"
                  />
                </span>
                <div className="icon_line"></div>
                <span className="icon-item">
                  <img
                    width="28"
                    height="16"
                    alt="JCB"
                    src="//img30.360buyimg.com/img/jfs/t1/102354/31/54589/3707/67287407F20fbce17/d3f7ab32831e98ae.png"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default members
