/**
 * 定期购进入主流程 - 计划填写页面 API
 * 文档：https://cf.jd.com/pages/viewpage.action?pageId=985082863
 */
import jsonp from 'jsonp'
import { http } from './request'
import { post, get, request } from './requestMethods'

// 设备指纹
const getJsToken = async () => {}

class mainPlanService {
  // static async planInit(params) {
  //   return await post({
  //     url: `/api/appPlan/toCreatePlanInfoGoldenProcess`,
  //     data: {
  //       activityId: 35549,
  //       skuId: 4068636,
  //       periodNum: 5,
  //       sizePeriod: 3,
  //       provinceId: 1,
  //       cityId: 2810,
  //       countyId: 51081,
  //       townId: 0,
  //       prescriptId: 0,
  //     },
  //   });
  // }
  // static async getPoolData(params) {
  //   return await get({
  //     url: `/pool?body={"pid":"0101741"}&source=global`,
  //   });
  // }
  // static async getAggPoolData(params) {
  //   return await get({
  //     url: `/aggPool?body=${JSON.stringify(params)}&source=global`,
  //   });
  // }
  // static async getDiagramData(params) {
  //   return new Promise(function (resolve, reject) {
  //     jsonp(`//lapi.jd.com/pool?body={"pid":"0101741"}&source=global`, null, function (err, res) {
  //       if (err) {
  //         reject(err);
  //       } else {
  //         if (res.code === "-1") {
  //           reject(err);
  //         }
  //         resolve(res);
  //       }
  //     });
  //   });
  // }

  //是否登录
  static isLogin() {
    return new Promise(function (resolve, reject) {
      jsonp(`//passport.jd.com/loginservice.aspx?method=Login`, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }
  //请求用户信息
  static userInfoData() {
    return new Promise(function (resolve, reject) {
      jsonp(`//passport.jd.com/user/petName/getUserInfoForMiniJd.action`, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }
  //请求用户信息
  static getMedelsData() {
    return new Promise(async function (resolve, reject) {
      const fid = 'jsfbox_user_scoreAndLevel'
      const appid = 'new-cms-channel'
      const params = {
        source: 'pc-hwhome',
      }
      let url = `//api.m.jd.com/client.action?functionId=${fid}&loginType=3&appid=${appid}&body=${JSON.stringify(
        params,
      )}`
      try {
        // 设备指纹
        let jsToken = ''
        window.getJsToken &&
          window.getJsToken(async function (res: any) {
            jsToken = res.jsToken || ''
            url = `${url}&x-api-eid-token=${jsToken}`
            const response = await mainPlanService.getMedels(url)
            resolve(response)
          }, 3000)
      } catch (error) {
        console.log(error)
        window.jmfe.jsagentReport(
          window.jmfe.JSAGENT_EXCEPTION_TYPE.business, //固定值不变
          700, //固定值: 异常码
          `接口异常-${fid}`, // 异常信息
          {
            fid, // 网关对应的functionid
            error: JSON.stringify(error),
            params: params, // body序列化后的字符；由于浏览器对url有长度限制，body约定限定在1000字符内是绝对满足上报条件的，超过部分前端自行截断。
          },
        )
        const response = await mainPlanService.getMedels(url)
        reject(response)
      }
    })
  }
  //判断用户是否是新人
  static getMedels(url) {
    return new Promise(function (resolve, reject) {
      jsonp(url, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }
  //判断用户是否是新人
  static getIsNewUserData() {
    return new Promise(function (resolve, reject) {
      jsonp(`//ai.jd.com/index_new.php?app=Newuser&action=isNewuser`, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }
  //顶通接口
  static getTopBannerData(id) {
    return new Promise(function (resolve, reject) {
      jsonp(`//lapi.jd.com/pool?body={"pid":${JSON.stringify(id)},"bak":0}&source=global`, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }
  //三级类目-一级类目接口
  static getPoolData(id) {
    return new Promise(function (resolve, reject) {
      jsonp(`//lapi.jd.com/pool?body={"pid":${JSON.stringify(id)}}&source=global`, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }
  //三级类目-二级和三级的接口数据
  static getAggPoolData(params) {
    return new Promise(function (resolve, reject) {
      jsonp(`//lapi.jd.com/aggPool?body=${JSON.stringify(params)}&source=global`, null, function (err, res) {
        if (err) {
          reject(err)
        } else {
          if (res.code === '-1') {
            reject(err)
          }
          resolve(res)
        }
      })
    })
  }

  static async getRequestData(id) {
    const qryParam = {
      stage: 0,
      commentType: '',
      sourceCode: 'pcDt',
      pageId: '',
      qryParam: JSON.stringify([
        {
          type: 'advertGroup',
          mapTo: 'advertInfos',
          id: id,
        },
      ]),
      applyKey: 'gift_card',
    }
    const body = JSON.stringify(qryParam)
    return http
      .get(`//api.m.jd.com?functionId=qryCompositeMaterials&body=${body}&client=wh5&siteClientVersion=`, {})
      .then((resp: any) => {
        // console.log(resp, "resp");
        return resp.data
      })
  }
  static async getRequestGoodsData(id) {
    const qryParam = {
      stage: 0,
      commentType: '',
      sourceCode: 'pcDt',
      pageId: '',
      qryParam: JSON.stringify([
        {
          type: 'productGroup',
          mapTo: 'gift_card',
          id: id,
        },
      ]),
      applyKey: 'gift_card',
    }
    const body = JSON.stringify(qryParam)
    return http
      .get(`//api.m.jd.com?functionId=qryCompositeMaterials&body=${body}&client=wh5&siteClientVersion=`, {})
      .then((resp: any) => {
        // console.log(resp, "resp");
        return resp.data
      })
  }
}

export default mainPlanService
