import React, { useState, useEffect, createRef } from 'react'
import ReactDOM from 'react-dom'
// import { useStores } from '../store/index'
import '@app/pages/App.scss'
// import { observer, inject } from 'mobx-react'
import mainPlanService from '@app/api/mainPlanService'
import { sendPv } from '@app/utils/mta'
import Menu from '@app/pages/LeftSide/index'
import Banner from '@app/pages/Banner/index'
import Members from '@app/pages/Members/index'
import Promote from '@app/pages/Promote/index'
import Panking from '@app/pages/RankingEdition/index'
import ChannelSquare from '@app/pages/ChannelSquare/index'
import Recommend from '@app/pages/recommendYou/index'
import ActivityTop from '@app/pages/topReached/index'

const App = () => {
  // const store = useStores()
  const [atmData, setAtmData] = useState<any>([])

  const topReachedFn = () => {
    ReactDOM.render(<ActivityTop />, document.getElementById('topBanner'))
  }

  const getPromoteData = async () => {
    mainPlanService.getRequestData(window['serverIdList'].atmosphereId).then((res) => {
      // console.log(res.advertInfos.list, '氛围图')
      if (res.advertInfos.list) {
        setAtmData(res.advertInfos.list)
      }
    })
  }
  useEffect(() => {
    // sendPv('Editorder')
    setTimeout(() => {
      let topDom: any = document.getElementById('top-banner')
      if (topDom) {
        topDom.parentNode.removeChild(topDom)
      }
    }, 500)
    topReachedFn()
    //初始化信息
    getPromoteData()
  }, [])
  return (
    <div className="center">
      <div className="floor f1">
        <div className="clr top_con">
          <div className="col left_category">
            <Menu></Menu>
          </div>
          <div className="col diagram">
            <Banner></Banner>
          </div>
          <Members></Members>
        </div>
        {window['serverIdList'].atmosphereId && atmData && atmData.length > 0 ? (
          <a
            className="atmosphere"
            onClick={() => {
              if (atmData[0].link) {
                window.open(atmData[0].link)
              }
              return
            }}
            style={{
              cursor: atmData[0].link ? 'pointer' : 'default',
              background: `url(${atmData[0].pictureUrl}) no-repeat`,
              backgroundSize: '100%',
            }}
          ></a>
        ) : null}
      </div>
      <div className="floor clr">
        <Promote></Promote>
      </div>
      {/* <div className="floor clr">
        <Panking></Panking>
      </div> */}
      <div className="floor clr">
        <ChannelSquare></ChannelSquare>
      </div>
      <div className="floor clr">
        <Recommend></Recommend>
      </div>
    </div>
  )
}

export default App
